import React, { useState, useEffect } from 'react'
import { Layout, Menu, Tooltip } from 'antd'
import {
	LaptopOutlined,
	CarOutlined,
	SearchOutlined,
	IdcardOutlined,
	PoweroffOutlined,
	SafetyCertificateOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { connect } from 'react-redux'
import { logout } from '../action/user'

import { enquireScreen } from 'enquire-js'

import { requireRole } from '../utils'

import { useLocation } from 'react-router-dom'

const { Header } = Layout

const HeaderLayout = ({ user, dispatch }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const query = new URLSearchParams(location.search)

	const [isMobile, setIsMobile] = useState(false)
	const [isPopup, setIsPopup] = useState(false)
	const [show, setShow] = useState(false)

	useEffect(() => {
		setIsPopup(query.get('isPopup') != null)
		enquireScreen((b) => {
			setIsMobile(b)
		})
	}, [query])

	const handleLogout = (e) => {
		dispatch(logout())
		navigate('/')
		setShow(true)
	}

	var key = location.pathname.split('/')[1]

	const iconStyle = {
		fontSize: '18px',
		marginRight: 0,
	}
	if (isMobile) {
		return (
			<Header
				className='header prevent-dragging'
				style={{
					padding: 0,
					display: isPopup ? 'none' : 'block',
				}}
			>
				<Menu
					theme='dark'
					mode='horizontal'
					defaultSelectedKeys={['']}
					selectedKeys={[key]}
					style={{
						lineHeight: '64px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Menu.Item
						key='monitoring'
						style={{
							textDecoration: 'none',
						}}
					>
						<Link to='/monitoring'>
							<LaptopOutlined style={iconStyle} />
						</Link>
					</Menu.Item>
					{requireRole(user, 'only_search') && (
						<Menu.Item key='car'>
							<Link to='/car'>
								<CarOutlined style={iconStyle} />
							</Link>
						</Menu.Item>
					)}
					{requireRole(user, 'only_search') && (
						<Menu.Item key='search'>
							<Link to='/search'>
								<SearchOutlined style={iconStyle} />
							</Link>
						</Menu.Item>
					)}
					{requireRole(user, 'only_search') && (
						<Menu.Item key='ticket'>
							<Link to='/ticket'>
								<IdcardOutlined style={iconStyle} />
							</Link>
						</Menu.Item>
					)}
					{user != null ? (
						<Menu.Item key='logout' onClick={handleLogout}>
							<Tooltip placement='leftBottom' title={user.name}>
								<PoweroffOutlined style={iconStyle} />
							</Tooltip>
						</Menu.Item>
					) : (
						<Menu.Item key='login'>
							<Link to='/login'>
								<SafetyCertificateOutlined style={iconStyle} />
							</Link>
						</Menu.Item>
					)}
				</Menu>
			</Header>
		)
	} else {
		return (
			<Header
				className='header prevent-dragging'
				style={{ display: isPopup ? 'none' : 'block' }}
			>
				<Menu
					theme='dark'
					mode='horizontal'
					defaultSelectedKeys={['']}
					selectedKeys={[key]}
					style={{ lineHeight: '64px', display: 'block' }}
				>
					<Menu.Item key=''>
						<Link to='/' style={{ marginLeft: -10 }}>
							<img
								alt='title'
								src='/assets/title.png'
								style={{
									maxHeight: 36,
									width: 120,
								}}
							/>
						</Link>
					</Menu.Item>
					<Menu.Item key='monitoring'>
						<Link to='/monitoring'>모니터링</Link>
					</Menu.Item>
					{/* <Menu.Item key='payment'>
							<Link to='/payment'>정산</Link>
						</Menu.Item> */}
					{requireRole(user, 'only_search') && (
						<Menu.Item key='car'>
							<Link to='/car'>차량 관리</Link>
						</Menu.Item>
					)}
					{/* {requireRole(user, 'only_search') && (
							<Menu.Item key='visit'>
								<Link to='/visit'>방문차량 관리</Link>
							</Menu.Item>
						)} */}
					{requireRole(user, 'only_search') && (
						<Menu.Item key='search'>
							<Link to='/search'>조회</Link>
						</Menu.Item>
					)}
					{requireRole(user, 'only_search') && (
						<Menu.Item key='ticket'>
							<Link to='/ticket'>임시 방문증</Link>
						</Menu.Item>
					)}
					{user != null && user.usePayment && (
						<Menu.Item key='payment'>
							<Link to='/payment'>정산</Link>
						</Menu.Item>
					)}
					{user != null && user.useDurationTime && (
						<Menu.Item key='dt'>
							<Link to='/dt'>체류시간</Link>
						</Menu.Item>
					)}
					{requireRole(user, 'admin') && (
						<Menu.Item key='settings'>
							<Link to='/settings'>설정</Link>
						</Menu.Item>
					)}
					{user != null ? (
						<Menu.Item
							key='logout'
							style={{ float: 'right' }}
							onClick={handleLogout}
						>
							<Tooltip placement='leftBottom' title={user.name}>
								<UserOutlined style={{ marginRight: 8 }} />
								로그아웃
							</Tooltip>
						</Menu.Item>
					) : (
						<Menu.Item key='login' style={{ float: 'right' }}>
							<Link to='/login'>
								<UserOutlined style={{ marginRight: 8 }} />
								로그인
							</Link>
						</Menu.Item>
					)}
				</Menu>
			</Header>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.user,
		isSessionInvalid: state.user.isSessionInvalid,
	}
}

export default connect(mapStateToProps)(HeaderLayout)
